import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "../screen/login";
import Sobre from "../screen/sobre";
import Dashboard from "../screen/Financeiro/dashboard";
import Consulta from "../screen/Financeiro/consulta";
import ListaLotes from "../screen/Financeiro/listaLotes";
import OpenLote from "../screen/Financeiro/openLote";
import Expire from "../screen/expire";
import { useAuth } from "../hooks/Authetication";
import Forbiden from "../screen/forbiden";
import NotFound from "../screen/notfound";

import Billstopay from "../screen/Financeiro/billstopay";
import GenerateBorder from "../screen/Financeiro/generateborder";
import ListaBordero from "../screen/Financeiro/listaBordero";
import OpenBordero from "../screen/Financeiro/openBordero";
import UpCnab from "../screen/Financeiro/upCnab"
// import { useAuth } from "../hooks/Authetication"


import Extrato from "../screen/Financeiro/extrato";
import ExtratoDetail from "../screen/Financeiro/extrato/[id]";




import SearchByMoviment from "../screen/Financeiro/movimentoInterno";
import AddMoviment from "../screen/Financeiro/movimentoInterno/[id]";


export default function MainRoutes() {
    const { user } = useAuth();
    return (
        <Routes>
            {user ? (
                <>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />

                    <Route path="/expire" element={ <Expire /> } />
                    
                    { /*  Rotas do financeiro  */}
                   <Route path="/financeiro/dashboard" element={<Dashboard />} />
                    <Route path="/financeiro/consulta" element={<Consulta />} />
                        <Route path="/financeiro/listaLotes" element={<ListaLotes />} />
                            <Route path="/financeiro/openLote/:id" element={<OpenLote />} />
                            
                            
                            <Route path="/financeiro/billstopay/" element={<Billstopay />} />
                            <Route path="/financeiro/generateborder/" element={<GenerateBorder />} />
                            <Route path="/financeiro/listbordero/" element={<ListaBordero />} />
                            <Route path="/financeiro/openbordero/:id" element={<OpenBordero />} />
                            <Route path="/financeiro/upcnab/" element={<UpCnab />} />

                            <Route path="/financeiro/extrato/" element={<Extrato />} />
                            <Route path="/financeiro/extrato/:id" element={<ExtratoDetail />} />

                            <Route path="/financeiro/movimento/" element={<SearchByMoviment />} />

                            <Route path="/financeiro/movimento/:id" element={<AddMoviment />} />


                </>
            ) : (
                <>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />

                    <Route path="/sobre" element={<Sobre />} />
                    <Route path="/expire" element={ <Expire /> } />
                    <Route path="*" element={<NotFound /> } />
                    

                    {/* As rotas abaixo são de acesso negado */}
                    
                    <Route path="/dashboard" element={<Forbiden /> } />

                    <Route path="/consulta" element={<Forbiden />} />
                        <Route path="/listaLotes" element={<Forbiden />} />
                            <Route path="/openLote/:id" element={<Forbiden />} />

                </>

            )}

                   
             


        </Routes>
    )

}