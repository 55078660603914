import React from 'react';
import styled from 'styled-components';
import { FaBars, FaUserCircle, } from 'react-icons/fa';
import { FiLogOut, FiSettings } from 'react-icons/fi';



export const section = styled.div`
    flex:1;
    display: grid;
    padding:30px;
    height: 100%;
    weight:100%;
    margin:20px;
    border-radius:20px;
    background-color: #f8f8ff;
    justify-content:center;


`

export const textTitleScreen = styled.text`
font-weight: bolder ;
color: #3A3A3A;
font-weight: 400;
font-size: 1.75rem;
padding-bottom: 10px;

`
