import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import Alerta from "../../components/alerta";


const AlertContext = createContext({});

function AlertaProvider({ children }) {
    const [title, setTitle] = useState('');

    const [message, setMessage] = useState('');
    const [color, setColor] = useState('default');
    const [showAlerta, setShowAlerta] = useState(false);

    function show() {
        setTimeout(() => {
            setShowAlerta(false);
        }, 6000)
    }

    function hideAlert() {
      setShowAlerta(false);
    }


    function showAlert({ title = 'Atenção', message, type = 'primary' }) {
        setMessage(message);
        setTitle(title);
        setColor(type);
        setShowAlerta(true);
    
    }

    function showAlertTime({ title = 'Atenção', message, type = 'primary' }) {
        setMessage(message);
        setTitle(title);
        setColor(type);
        setShowAlerta(true);
        show();
    }

    return (
        <AlertContext.Provider value={{ showAlert, showAlertTime, hideAlert }}>
            <Alerta title={title} show={showAlerta} message={message} color={color} />
            {children}
        </AlertContext.Provider>
    );

}

function useAlerta() {
    const context = useContext(AlertContext);

    if (!context) {
        throw new Error('useToast must be used within an ToastProvider');
    }

    return context;
}

export { AlertaProvider, useAlerta };