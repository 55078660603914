import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useAuth } from "../../../hooks/Authetication";
import { useAlerta } from "../../../hooks/alerta";
import Checkbox from "./Checkbox";


import { Container, Row, Col, Table, InputGroup, Form, Modal, Button } from 'react-bootstrap';

export default function GenerateBorder() {

    const [dataApi, setDataApi] = useState([]);
    const { user } = useAuth();
    const [checkBox, setChackbox] = useState([]);
    const [isCheck, setIsCheck] = useState([]);
    const [filBanco, setFilbanco] = useState('');
    const [filDataI, setFilDataI] = useState('');
    const [filDataF, setFilDataF] = useState('');
    const [listaCheck, setListaCheck] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [selBanco, setSelBanco] = useState(1);
    const { showAlert, hideAlert, showAlertTime } = useAlerta();



   async function envBordero() {
              
          if(isCheck.length === 0){
            setModalShow(false)
            showAlertTime({ title: "Atenção", message: String('Não é possivel gerar um arquivo com 0 títulos.'), type: "danger" })
            return;
        }


        var data = JSON.stringify({
            "uuid": user.uuid,
            "bancoPag": selBanco, 
            "hashPag" : {isCheck }

        })

    var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        // 'Authorization': `Bearer ${user.token}`,
        const resposta = await ilikeApi.post("/cnab-fin-adm/gera-bordero", data, axiosConfig);
        if (resposta.data.error === 0) {
            setModalShow(false)
            showAlertTime({ title: "Sucesso", message: String(resposta.data.msg), type: "success" })
            setDataApi([]);
            setChackbox([]);
            setIsCheck([]);
            setIsCheckAll(false);

        }else{
            setModalShow(false)
            showAlertTime({ title: "Atenção", message: String(resposta.data.msg), type: "danger" })
            setDataApi([]);
            setChackbox([]);
            setIsCheck([]);
            setIsCheckAll(false);
        }


    }


    async function chamaapi() {
        setDataApi([]);
        setChackbox([]);
        setIsCheck([]);
        setIsCheckAll(false);
        var data = JSON.stringify({
            "uuid": user.uuid,
            "filter": {
                "status": "2",
                "dateI": null,
                "dateF": null,
                "banco": filBanco
            }

        });

        console.log(data);
        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };
        // 'Authorization': `Bearer ${user.token}`,
        const resposta = await ilikeApi.post("/consultas-fin-adm/list-titulos", data, axiosConfig);
        if (resposta.data.error === 0) {
            setDataApi(resposta.data.res);
        }
    }


    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Envio de lote para geração de arquivo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <S.textTitleTble>Selecione o banco a qual deseja enviar a remessa. </S.textTitleTble>

                        <Col md={4}>
                            <Form.Group className="mb-3" name='selBanco' id="selBanco">
                                <Form.Select value={selBanco} onChange={e => setSelBanco(e.target.value)}>
                                    <option value="1">Bradesco</option>
                                    <option value="2">B.Inter</option>
                                    <option value="3">Santander</option>
                                    <option value="4">Itaú</option>


                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" className="btn btn-success btn-sm btn-block" onClick={() => envBordero()}>  <S.FaCheckLo /> Aceito</Button>
                    <Button type="button" className="btn btn-danger btn-sm btn-block" onClick={props.onHide}>Não aceito</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(dataApi.map(li => li.hash));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;

        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }

    };

    useEffect(() => {
        chamaapi();
    }, [filBanco, filDataI, filDataF]);


    return (
        <>
            <Menu />
            <S.body>
                <S.nameuser> <S.FaUse /> Bem vindo -  {user.name}</S.nameuser>
                <S.textTitleScreen>Conta a Pagar / Gerar Borderô</S.textTitleScreen>
                <Divider />
                <S.section>
                    <S.nameuser> Filtros </S.nameuser>
                    <Divider />

                    <Col md={2}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label> Banco </Form.Label>
                            <Form.Select aria-label="Selecione um banco" onChange={text => setFilbanco(text.target.value)}>
                                <option value="">Todos</option>
                                <option value="237">Bradesco</option>
                                <option value="077">B.Inter</option>
                                <option value="033">Santander</option>
                                <option value="341">Itaú</option>


                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Divider />

                    <Row>
                        <Col md={12}>

                            <S.cardTable>
                                <S.textTitleTble>Títulos em aberto</S.textTitleTble>
                                <div>
                                    <Checkbox
                                        type="checkbox"
                                        name="selectAll"
                                        id="selectAll"
                                        handleClick={handleSelectAll}
                                        isChecked={isCheckAll}
                                    />
                                    Selecionar todos
                                </div>

                                <Table striped size="md" responsive>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Pagador</th>

                                            <th>Recebedor</th>
                                            <th>Valor</th>
                                            <th>Vencimento</th>
                                            <th>Cod.Banco</th>
                                            <th>Check</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataApi.map((item) => (
                                            <tr key={item.hash}>
                                                <td>{item.codigo}</td>
                                                <td>{item.Pagador}</td>
                                                <td>{item.Recebedor}</td>
                                                <td>R$ {item.Valor}</td>
                                                <td>{item.Vencimento}</td>
                                                <td>{item.Cob}</td>

                                                <Checkbox
                                                    key={item.hash}
                                                    type="checkbox"
                                                    name={item.hash}
                                                    id={item.hash}
                                                    handleClick={handleClick}
                                                    isChecked={isCheck.includes(item.hash)}
                                                />
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </S.cardTable>
                            <br></br>
                            <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => setModalShow(true)}>  <S.FaCheckLo /> Continuar</button>



                            <MyVerticallyCenteredModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </Col>

                    </Row>
                </S.section>
                <Footer />
            </S.body>

        </>


    );
}


