import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import * as S from './style.js'
import * as animationData from './loading.json'
import Lottie from 'react-lottie';


export default function Loading({ msg = null}) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };


    return (
        <>
        <S.section> 
        <Lottie options={defaultOptions}
                    height={300}
                    width={300}
        />

        <S.textTitleScreen> {msg} </S.textTitleScreen>

        </S.section>
        </>
    )
}
