import React from 'react';
import styled from 'styled-components';
import { FaBars, FaUserCircle,  } from 'react-icons/fa';
import { FiLogOut, FiSettings  } from 'react-icons/fi';



export const section = styled.div`
    padding:30px;
    height: 80%;
    margin-left:20px;
    box-shadow: inset 0 0 1em #222f3e, 0 0 1em #f8f8ff;
    margin:20px;
    border-radius:20px;
    background-color: #f8f8ff;

`