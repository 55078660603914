import React, { Component, useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";

import *  as S from './style';
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useAuth } from "../../../hooks/Authetication";
import { useAlerta } from "../../../hooks/alerta";


import { Container, Row, Col, Table, InputGroup, FormControl } from 'react-bootstrap';
import axios from "axios";

export default function SearchByMoviment() {

    const navigate = useNavigate();

    const { user } = useAuth();
    const [ keyName, setKeyName ] = useState();
    const [ dataApi, setDataApi ] = useState([]);

    const { showAlert, showAlertTime } = useAlerta();


  async  function callApi(key){

         setKeyName(key.val);
         setDataApi([]);

        if(key.val.length > 5){        
        var data = JSON.stringify({
            "metodo": "listByName", 
            "key": keyName
        });

        try {
            const response = await ilikeApi.post(`list-clientes-adm/con-clientes`, data);
            
            if(response.data.erro === 0){

                setDataApi(response.data.res);

                console.log(dataApi)

            }

       
       
        } catch (error) {
                console.log(error)        
        }
    
    }   
        
    }




    return (
        <>
            <Menu />
            <S.body>
                <S.nameuser> <S.FaUse /> Bem vindo -  {user.name}</S.nameuser>
                <S.textTitleScreen> Financeiro /   </S.textTitleScreen><small> Movimento Interno</small>
                <Divider />
                <S.section>

                <Row> 
                    <Col md={6}>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder="Digite o nome do cliente"
                            aria-label="Digite o nome do cliente"
                            aria-describedby="basic-addon2"
                            value ={keyName}
                            onChange={e =>  callApi({ val: e.target.value })}
                        />
                    </InputGroup>
                    <div style={{width:"100%", backgroundColor: "#EEE", borderRadius:"4px", padding: "10px",  opacity: 0.5, }}>
                         {
                            dataApi.map((item) => (   
                            <div onClick={()=> navigate(`/Financeiro/movimento/${item.uuid}`)} style={{display: "flex", fontColor: "#000", justifyContent: "space-between", }} > 
                               <span>  <b>  {item.nome}  </b>  </span>  <span>  <b>   {item.documento} </b> </span>  
                             </div>

                            ))
                            
                        } 
                
                </div>
                 


                    </Col>
                </Row>


                </S.section>
                <Footer />
            </S.body>

        </>


    );
}


