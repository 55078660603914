import React, { useState} from 'react';
import Sidebar from './components/Sidebar';
import ToolBar from './components/ToolBar';
import Backdrop from './components/Backdrop';
import Footer from '../footer';
import "./style.css";


export default function Menu() {
    
    const[sidebar, setSidebar] = useState(false);

    const toggleSidebar = () =>{
        setSidebar(!sidebar)
        console.log('clidado'+ sidebar)
    }
    return (
        <>

            <ToolBar openSidebar={toggleSidebar} />
            <Backdrop sidebar={sidebar} closeSidebar={toggleSidebar} />
            <Sidebar sidebar={sidebar} />


        </>
    )
}
