import React, { Component, useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/loading"

import { useAlerta } from "../../../hooks/alerta";



import { Container, Row, Col, Table, Modal, Button } from 'react-bootstrap';




export default function OpenBordero() {
    const { showAlert, hideAlert, showAlertTime } = useAlerta();

    const { id } = useParams();
    console.log(id);
    const [dataApi, setDataApi] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadMsg, setLoadMsg] = useState('Baixando Arquivo');



    const navigate = useNavigate();


    async function createCnab(){
            setLoadMsg('Aguarde estamos processando o arquivo')
            setLoad(true);

            var data = JSON.stringify({
                
                "uuid" : "b2f98b04-b2ff-8405-d044-f777579825e323202112362307",
                "filter" : { 
                    "hash" : id
                }
            
        });

        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',

            }
        };

            const resposta = await ilikeApi.post("/cnab-fin-adm/gera-cnab", data, axiosConfig);
            console.log(resposta.data);
            if (resposta.data.error === 0) {
                setModalShow(false);

                setLoadMsg('Vamos baixar o arquivo')
                setTimeout(() => {
                    window.open(`https://prod-gtw.iliketechnology.com.br/${resposta.data.link}`, '_blank');
                    setLoad(false);
                },5000);
              



            }else{
                setModalShow(false);

                showAlertTime({ title: "Aguarde", message: resposta.data.msg, type: "danger" })
                setDataApi([]);
                setTimeout(() => {
                    setLoad(false);

                   // navigate('/financeiro/dashboard');
                },5000);

            }



    }





    useEffect(() => {

        async function chamaapi() {
            setDataApi([]);

            var data = JSON.stringify({
                
                    "uuid" : "b2f98b04-b2ff-8405-d044-f777579825e323202112362307",
                    "filter" : { 
                        "hash" : id,
                        "dateI" : null, 
                        "dateF": null, 
                        "banco": ""
                    }
                
            });

            var axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',

                }
            };

            // 'Authorization': `Bearer ${user.token}`,


            const resposta = await ilikeApi.post("/cnab-fin-adm/open-bordero", data, axiosConfig);
            if (resposta.data.error === 0) {

                setDataApi(resposta.data.res);

            }
            console.log(resposta.data)

        }

        chamaapi();

    }, []);





    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Aprovação de geração de arquivo Cnab
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Confirmação</h4>
                    <p>
                        Declaro que estou gerando o arquivo Cnab e adicionarei ao banco para as devidas liquidaçoes.
                    </p>
                    <small> Id do borderô: {id} </small>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => createCnab()}>  <S.FaCheckLo /> Aceito</button>

                    <Button type="button" className="btn btn-danger btn-sm btn-block" onClick={props.onHide}>Não aceito</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    return (
        <>
            <Menu />
            <S.body>

            <S.nameuser> <S.FaUse /> Bem vindo -  Emerson Rodrigues de Freitas</S.nameuser>
          {load ? (
              <>
                <Loading msg={loadMsg} />
            </>
            ):(
                <>  
                <S.textTitleScreen>Títulos do Borderô   </S.textTitleScreen>
                <Divider />
                <S.section>


                    <Row>

                        <S.cardTable>
                        <S.textTitleTble>Títulos</S.textTitleTble>


                            <Table striped size="sm" responsive>
                                <thead>
                                    <tr>
                                        <th>Borderô</th>
                                        <th>ID</th>
                                        <th>Favorecido</th>
                                        <th>Valor</th>
                                        <th>Dt. Vencimento</th>
                                        <th>Remover</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {dataApi.map((item) => (
                                        <tr key={item.SJ3_NUM}>
                                            <td>{item.SJ2_NUM}</td>
                                            <td>{item.SJ3_NUM}</td>
                                            <td> {item.SJ4_NM_RECEBEDOR}</td>

                                            <td> R$ {item.SJ3_VALOR_TITULO}</td>
                                            <td>{item.SJ3_DT_VENCIMENTO}</td>
                                            <td>  <button type="button" className="btn btn-link btn-sm btn-block" onClick={() => alert(`Função Indisponivel`)}>  <S.FaRemove /></button> </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => setModalShow(true)}>  <S.FaCheckLo /> Criar arquivo</button>



                            <MyVerticallyCenteredModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </S.cardTable>


                    </Row>
                </S.section>
                </>
                ) } 
                <Footer />
            </S.body>


        </>


    );
}


