import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as S from './style.js'


export default function Divider() {



    return (
        <>
        <S.divider /> 
        </>
    )
}
