import React from 'react';
import styled from 'styled-components';
import { FaFolderOpen, FaUser, } from 'react-icons/fa';


export const body = styled.div`
position: fixed;
background: #e2e8f0;
width: 100%;
height: 100%;
overflow: auto;

`


export const cadLottie = styled.div`
margin-top: 50px;
`

export const container = styled.div`
flex:1 ;
flex-direction: column;
display:grid;
width: 100%;
justify-content: center;

`


export const textTitle = styled.text`
font-family:Montserrat;
font-weight:700;
font-size: 1.875rem;
line-height: 1.33;
font-aling: center;
justify-content: center;
margin-left: auto;
margin-right: auto;

`

export const textText = styled.text`
font-family:Montserrat;
font-size: 1.475rem;
line-height: 1.33;
font-aling: center;
margin-left: auto;
margin-right: auto;
`

export const divButton = styled.div`

margin-left: auto;
margin-right: auto;
`