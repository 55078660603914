import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { destroyCookie, parseCookies, setCookie } from "nookies";
import Lottie from 'react-lottie';
import Footer from '../../components/footer';
import * as animationData from './clock.json'
import * as S from './style'
import { useNavigate } from "react-router-dom";

export default function Expire() {
    const navigate = useNavigate();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {

        destroyCookie(null, 'client.session');

    }, []);

    return (
        <>
        <S.body>

            <S.cadLottie>
                <Lottie options={defaultOptions}
                    height={300}
                    width={300}
                />

            </S.cadLottie>
            <S.container> 
            <S.textTitle>Ops! ¯\(°_o)/¯, acho que expirou sua sessão.</S.textTitle>
           <br></br>
            <S.textText> Para continuar ultilizando nossos serviços por favor realize o login novamente</S.textText>
           <br></br>
           <S.divButton> 
            <button type="button" style={{width:"300px"}} className="btn btn-success btn-md btn-block" onClick={() => navigate('/')}>  Login </button>
            </S.divButton> 
           </S.container>
           <Footer /> 

        </S.body>
        </>
    );


}

