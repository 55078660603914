import React from 'react';
import styled  from 'styled-components';







export const MainDiv = styled.div`
  color: pink;
  background-color: red;
  width:100%
`


export const headerLogo = styled.img`
     width: 100pxx;
    height: 100px;
    margin: 0px;
    width:100%
`


export const cardLogin = styled.div`
    margin-top:40px;
`





