import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";




import { Container, Row, Col, Table } from 'react-bootstrap';




export default function Consulta() {
    return (
        <>
            <Menu />


            <S.section>

                <h5>Consultas</h5>
                <Divider />
                <Container>
                    <Row>
                        <Col>
                            <S.card>

                                <S.textCardTitle    >Saldo:</S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: 890,00</S.textCard>
                            </S.card>



                        </Col>

                        <Col>
                            <S.card>

                                <S.textCardTitle>Empenhado:</S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: 890,00</S.textCard>
                            </S.card>


                        </Col>
                        <Col>
                            <S.card>


                                <S.textCardTitle>Liquidado Hoje:</S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: 370,00</S.textCard>
                            </S.card>


                        </Col>


                    </Row>

                    <br></br><br></br>

                    <Row>
                        <S.card>
                        <h5>Últimos lancamentos</h5>

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Data</th>
                                        <th>Destinatário</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>26/03/2022</td>
                                        <td>Emerson Rodrigues de Freitas</td>
                                        <td>R$ 100,00</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>26/03/2022</td>
                                        <td>Josefa  de Freitas</td>
                                        <td>R$ 80,00</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>26/03/2022</td>
                                        <td>Murilo Cardoso</td>
                                        <td>R$ 190,00</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>26/03/2022</td>
                                        <td>Murilo Cardoso</td>
                                        <td>R$ 190,00</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>26/03/2022</td>
                                        <td>Murilo Cardoso</td>
                                        <td>R$ 190,00</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </S.card>
                    </Row>
                </Container>




            </S.section>
            <Footer />


        </>


    );
}


