import React from 'react';
import styled from 'styled-components';

export const divider = styled.div`
margin-top:5px;

margin-bottom:30px;
height:1px;
width:100%;
border-top:1px solid #3A3A3A;



`