import React from 'react';
import styled from 'styled-components';




export const section = styled.div`
    top:0;
    padding:30px;
    margin-left:20px;
    margin-right:20px;
    margin-top:100px;
    box-shadow: inset 0 0 1em #222f3e, 0 0 1em #FFF;
    border-radius:20px;
    background-color: #FFF;
`

export const card = styled.div`
border-radius: 5px;
box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
padding: 20px;
width: 100%;
transition: all 0.3s ease-out;

`

export const MainDiv = styled.div`
  color: pink;
  background-color: red;
  width:100%
`

export const textCardTitle = styled.text`
font-weight: bolder;
text-size: 16px
`


export const textCard = styled.text`
text-size: 12px
`


export const headerLogo = styled.img`
     width: 100pxx;
    height: 100px;
    margin: 0px;
    width:100%
`


export const cardLogin = styled.div`
    margin-top:40px;
`





