import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useNavigate } from "react-router-dom";



import { Container, Row, Col, Table } from 'react-bootstrap';




export default function ListaBordero() {

    const [dataApi, setDataApi] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        async function chamaapi() {


            var data = JSON.stringify({
                
                    "uuid" : "b2f98b04-b2ff-8405-d044-f777579825e323202112362307",
                    "filter" : { 
                        "status" : "1",
                        "dateI" : null, 
                        "dateF": null, 
                        "banco": ""
                    }
                
            });

            var axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',

                }
            };

            // 'Authorization': `Bearer ${user.token}`,


            const resposta = await ilikeApi.post("/cnab-fin-adm/list-bordero", data, axiosConfig);
            if (resposta.data.error === 0) {

                setDataApi(resposta.data.res);

            }
            console.log(resposta.data)

        }

        chamaapi();

    }, []);


    return (    
        <>
            <Menu />
            <S.body>
            <S.nameuser> <S.FaUse /> Bem vindo -  Emerson Rodrigues de Freitas</S.nameuser>

                <S.textTitleScreen>Lista de Borderô</S.textTitleScreen>
                <Divider />
                <S.section>

                    <Row>
                        <Col md={12}>
                            <S.cardTable>
                                <h5>Borderôs</h5>

                                <Table striped size="sm" responsive>
                                    <thead>
                                        <tr>
                                            <th>Borderô</th>
                                            <th>Quantidade de Títulos</th>
                                            <th>Data</th>
                                            <th>Hora</th>
                                            <th>Tipo</th>
                                            <th>Nome</th>
                                            <th>Banco</th>
                                            <th>DT.Recepção</th>
                                            <th>HR.Recepção</th>
                                            <th>Acessar</th>
                                            <th>Download</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataApi.map((item) => (
                                            <tr key={item.SJ2_NUM}>
                                                <td>{item.SJ2_NUM}</td>
                                                <td>{item.Quantidade}</td>
                                                <td>{item.SJ2_DT}</td>
                                                <td>{item.SJ2_HR}</td>
                                                <td>{item.SJ2_TP}</td>
                                                <td>{item.SJ2_NM_BORDERO}</td>
                                                <td>{item.SI1_NM_INST}</td>
                                                <td>{item.SJ2_DT_RECEPCAO}</td>
                                                <td>{item.SJ2_HR_RECEPCAO}</td>
                                                
                                                {item.SJ2_APROVACAO === '0' && ( <td>  <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => navigate(`/financeiro/openbordero/${item.SJ2_HASH}`)}> <text> <S.FaFolderO /> Abrir </text></button> { }</td> )} 
                                                {item.SJ2_APROVACAO === '1' && ( <td>  <button type="button" className="btn btn-danger btn-sm btn-block" onClick={() => alert('Bloqueado')}> <text> <S.FaFolderO /> Bloqueado </text></button> { }</td> )} 
                                                
                                                
                                                {item.SJ2_APROVACAO === '0' && ( <td>  <button type="button" className="btn btn-link btn-sm btn-block" onClick={() => navigate(`/financeiro/openbordero/${item.SJ2_HASH}`)}> <text> <S.FaFolderO />  </text></button> { }</td> )} 
                                                {item.SJ2_APROVACAO === '1' && ( <td>  <button type="button" className="btn btn-link btn-sm btn-block" onClick={() =>  window.open(`https://prod-gtw.iliketechnology.com.br${item.SI1_PATH}${item.SJ2_ARQUIVO}`, '_blank')}> <text> <S.FaFolderO />  </text></button> </td> )} 
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </S.cardTable>
                        </Col>
                    </Row>
                </S.section>
                <Footer />
            </S.body>

        </>


    );
}


