import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { destroyCookie, parseCookies, setCookie } from "nookies";
import Lottie from 'react-lottie';
import Footer from '../../components/footer';
import * as animationData from './404.json'
import * as S from './style'
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {

        destroyCookie(null, 'client.session');

    }, []);

    return (
        <>
            <S.body>

                <S.cadLottie>
                    <Lottie options={defaultOptions}
                        height={400}
                        width={400}
                    />

                </S.cadLottie>
                <S.container>
                    <S.textTitle>Ops! ¯\_(ツ)_/¯, acho que  deu um  404.</S.textTitle>
                    <br></br>
                    <S.textTitle>Página não encontrada</S.textTitle>

                    <br></br>
                    <S.divButton>
                        <button type="button" style={{ width: "300px" }} className="btn btn-success btn-md btn-block" onClick={() => navigate('/')}>  Login </button>
                    </S.divButton>
                </S.container>
                <Footer />

            </S.body>
        </>
    );


}

