import React, { Component, useState, useEffect, useCallback } from "react";
// import { Link } from 'react-router-dom';
import { Link, useNavigate, useRoutes, useParams } from "react-router-dom";

import *  as S from './style';
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useAuth } from "../../../hooks/Authetication";
import { useAlerta } from "../../../hooks/alerta";
import { parseCookies } from "nookies";
import { FaFileExcel } from 'react-icons/fa';
import moment from "moment";
import { maskInscricao } from "../../../utils/maskInscricao"
import { moedaBr } from "../../../utils/moedaBr"


import { MyPagination, Pagination } from "../../../components/pagination";
import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import axios from "axios";

export default function AddMoviment() {

    const navigate = useNavigate();
    const { id } = useParams();
    const { user } = useAuth();
    const [datai, setDatai] = useState(null);
    const [hora, setHora] = useState(null);
    const [primaryCheck, setPrimaryCheck] = useState(true);
    const [codeOperation, setCodeOperation] = useState(null);

    const [remetente, setRemetente] = useState('');

    const [selectTp, setSelectTp] = useState([]);
    const [selectTpText, setSelectTpText] = useState('Remetente');
    const [descMovimento, setDescMovimento] = useState('');
    const [inscricao, setInscricao] = useState('')
    const [moneyReal, setMoneyReal] = useState('')




    const { showAlert, showAlertTime } = useAlerta();



    function setTpMoviment(tipo) {
        setPrimaryCheck(false)
        if (tipo === '0') {
            setPrimaryCheck(true)
            setCodeOperation(0)

            setSelectTpText('Remetente');
            const select = [
                { id: 1, type: "CRED -ABASTECIMENTO", value: "1" },
                { id: 2, type: "CRED TARIF - TED REM", value: "2" },
                { id: 3, type: "CRED - CDB BRADESCO", value: "3" },
                { id: 4, type: "CRED - CDB SANTANDER", value: "4" },
                { id: 5, type: "CRED - CDB INTER", value: "5" },
                { id: 6, type: "CRED - TARIFA DE CESTA", value: "6" },
            ];
            setSelectTp(select);
        } else {
            setSelectTpText('Favorecido');
            setCodeOperation(1)

            const select = [
                { id: 1, type: "I LIKE TED REM", value: "1" },
                { id: 2, type: "TARIF - TED REM", value: "2" },
                { id: 3, type: "TARIF DE LIQUIDACAO", value: "3" },
                { id: 4, type: "CESTA - BCO SANTANDER", value: "4" },
                { id: 5, type: "CESTA - BCO INTER", value: "5" },
                { id: 6, type: "CESTA - BCO INTER", value: "6" },
            ];
            setSelectTp(select);
        }

    }

    function handleChangeMask(event) {
        const { value } = event.target

        setInscricao(maskInscricao(value))
    }

    function handleChangeMoney(event) {
        const { value } = event.target
        setMoneyReal(moedaBr(value))
    }



    useEffect(() => {
        setTpMoviment('0')
        setPrimaryCheck(true)
    }, []);





    async function chamaapi() {

      

        if(descMovimento === ''){
            showAlertTime({ title: "Atenção", message: String('Preencha o tipo de movimento'), type: "danger" })
            return;
        }

        if(datai === null){
            showAlertTime({ title: "Atenção", message: String('Preencha uma data base'), type: "danger" })
            return;
        }

        if(hora === null){
            showAlertTime({ title: "Atenção", message: String('Preencha a hora da operação'), type: "danger" })
            return;
        }

        if(remetente === ''){
            showAlertTime({ title: "Atenção", message: String(`Preencha  o nome do  ${selectTpText} da operação`), type: "danger" })
            return;
        }

        if(inscricao === ''){
            showAlertTime({ title: "Atenção", message: String(`Preencha  o cpf ou cnpj do  ${selectTpText} da operação`), type: "danger" })
            return;
        }


        if(moneyReal === ''){
            showAlertTime({ title: "Atenção", message: String(`Preencha  o valor da operação`), type: "danger" })
            return;
        }


        const data = [
        {
            uuidOperacao : id, 
            tipoOperation : codeOperation, 
            descricao : descMovimento, 
            dtBase: datai, 
            horaBase: hora, 
            nmOperacao: remetente, 
            docInscricao: inscricao, 
            valor: moneyReal
        }
        ]

     
        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                 'Authorization': `Bearer ${user.access_token}`,
            }
        };

        // 'Authorization': `Bearer ${user.token}`,
        // var formdata = new FormData();
        // formdata.append("access_token", `${user.access_token}`);
        // // if (datai) { formdata.append("data_inicio", datai) };
        // // if (dataf) { formdata.append("data_fim", dataf) };



        try {
            const resposta = await ilikeApi.post(`/movimentos-adm/add-movimento/`, data, axiosConfig);
            console.log(resposta.data);

            if(resposta.data.error === 0){
                showAlertTime({ title: "Confirmado", message: String('Realizado com sucesso'), type: "success" })
                navigate('/Financeiro/Dashboard');
                return;

            }

            if(resposta.data.error === 0){
                showAlertTime({ title: "Error", message: String('Ocorreu um erro verifique o saldo do cliente'), type: "danget" })
                navigate('/Financeiro/Dashboard');
                return;

            }
            // var lista = [];

            // resposta.data.movimentacao.itens.forEach((item, index) => {

            //     item.forEach((item, index) => {

            //         lista.push(item)
            //     })

            // });

            // setTotal(lista.length);
            // lista.forEach((item, index) => {
            //     Object.assign(item, {
            //         id: index + 1
            //     });
            // });

            // setDataApi(lista);

            // setLista(lista);
        } catch (error) {

            console.log(error);
        }


    }
    //     setDataApi([]);

    //     setLista([]);
    //     setTotal(0);
    //     setPage(1)

    //     var data = JSON.stringify({
    //         "uuid": id,
    //         "data_inicio": datai,
    //         "data_fim": dataf,
    //         "status": null
    //     });


    //     // var axiosConfig = {
    //     //     headers: {
    //     //         'Content-Type': 'application/json',
    //     // 'Authorization': `Bearer ${user.access_token}`,


    //     //     }
    //     // };

    //     // 'Authorization': `Bearer ${user.token}`,
    //     var formdata = new FormData();
    //     formdata.append("access_token", `${user.access_token}`);
    //     if (datai) { formdata.append("data_inicio", datai) };
    //     if (dataf) { formdata.append("data_fim", dataf) };



    //     try {
    //         const resposta = await ilikeApi.post(`/banking/consulta-extrato/${id}`, formdata);
    //         console.log(resposta.data);


    //         var lista = [];

    //         resposta.data.movimentacao.itens.forEach((item, index) => {

    //             item.forEach((item, index) => {

    //                 lista.push(item)
    //             })

    //         });

    //         setTotal(lista.length);
    //         lista.forEach((item, index) => {
    //             Object.assign(item, {
    //                 id: index + 1
    //             });
    //         });

    //         setDataApi(lista);

    //         setLista(lista);
    //     } catch (error) {

    //         console.log(error);
    //     }



    // }





    return (
        <>
            <Menu />
            <S.body>
                <S.nameuser> <S.FaUse /> Bem vindo -  {user.name}</S.nameuser>
                <S.textTitleScreen> Financeiro /   </S.textTitleScreen><small> Movimento Interno</small>
                <Divider />
                <S.section>
                    <Row>


                        {/* <Col md={12} >
                        <br></br>
                        <div style={{  display: 'flex', justifyContent: "space-between"}}> 
                        <Button onClick={() => chamaapi()} > Filtrar</Button>

                        <Button variant="success" onClick={()=> printExcel()}> <S.FileExcel /> </Button>
                        </div>
                    </Col> */}







                        <Col md={12}>

                            <br></br>
                            <br></br>
                            <h3>Selecione o movimento</h3>
                     
                            <Col md={12}>
                                        <Form.Check
                                            inline
                                            label="Crédito"
                                            name="group1"
                                            type={'radio'}
                                            id={`tpMov-1`}
                                            value={'0'}
                                            onChange={(text) => setTpMoviment(text.target.value)}
                                            checked={primaryCheck}
                                        />

                                        <Form.Check
                                            inline
                                            label="Débito"
                                            name="group1"
                                            type={'radio'}
                                            id={`tpMov-2`}
                                            value={'1'}
                                            onChange={(text) => setTpMoviment(text.target.value)}
                                        />
                                    </Col>
                                    <br></br>
                            <br></br>
                            <S.cardTable>
                                <h3>Movimento</h3>
                                <br></br>
                                <Row>

                                 
                                    <Col md={3}>

                                        <Form.Group controlId="formBasicSelect">
                                            <Form.Label>Descrição do movimento</Form.Label>
                                            <Form.Control as="select"
                                                value={descMovimento}
                                                onChange={(e) => setDescMovimento(e.target.value)}
                                            >
                                                <option value='' > -- Selecione um movimento -- </option>

                                                {
                                                    selectTp.map((item) => (
                                                        <option key={item.id} value={item.value} > {item.type} </option>
                                                    ))

                                                }

                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Data Base</Form.Label>
                                            <input name="requested_order_ship_date"
                                                // value={moment(datai).format("DD-MMM-YYYY")}
                                                onChange={e => setDatai(e.target.value)}
                                                type="date" className="form-control" />
                                        </Form.Group>
                                    </Col>

                                    <Col md={3}>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Hora</Form.Label>
                                            <input name="requested_order_ship_date"
                                                // value={moment('22-02-2022').format("DD-MMM-YYYY")}
                                                onChange={e => setHora(e.target.value)}
                                                type="time" className="form-control" />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Label> Nome do {selectTpText}</Form.Label>
                                                    <Form.Control type="text" placeholder={`Nome ${selectTpText}`}  onChange={e => setRemetente(e.target.value)} />
                                                </Col>

                                                <br></br>
                                                <Col md={3}>
                                                    <Form.Label> Nº Inscrição {selectTpText}</Form.Label>
                                                    <Form.Control type="text" placeholder="Nº CPF / CNPJ" onChange={handleChangeMask} value={inscricao} />
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Col md={3}>
                                                <Form.Label> Valor </Form.Label>
                                                <Form.Control type="text" placeholder="R$ " onChange={handleChangeMoney} value={moneyReal} />
                                            </Col>
                                        </Form.Group>
                                    </Col>



                                </Row>
                                <Button onClick={() => chamaapi()} > Enviar</Button>

                            </S.cardTable>

                        </Col>
                    </Row>

                </S.section>
                <Footer />
            </S.body>

        </>


    );
}


