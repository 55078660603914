import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useAuth } from "../../../hooks/Authetication";
import { useAlerta } from "../../../hooks/alerta";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Table } from 'react-bootstrap';

export default function Billstopay() {

    const [dataApi, setDataApi] = useState([]);
    const { user } = useAuth();
    const { showAlert, showAlertTime } = useAlerta();
    const navigate = useNavigate();

    useEffect(() => {

        async function chamaapi() {

            console.log(user);
            var data = JSON.stringify({
                "uuid": user.uuid,
                "filter": {
                    "periodo_inicio": null,
                    "periodo_fim": null,
                    "status": '1,2,3,4,5,6,7,8'
                }
            });

            var axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                }
            };

            // 'Authorization': `Bearer ${user.token}`,


            const resposta = await ilikeApi.post("/consultas-fin-adm/list-titulos", data, axiosConfig);
            if (resposta.data.error === 0) {
                setDataApi(resposta.data.res);

            }
            console.log(resposta.data)

        }

        chamaapi();

    }, []);


    return (
        <>
            <Menu />
            <S.body>
                <S.nameuser> <S.FaUse /> Bem vindo -  {user.name}</S.nameuser>
                <S.textTitleScreen>Conta a Pagar</S.textTitleScreen>
                <Divider />
                <S.section>



                    <br></br><br></br>

                    <Row>
                        <Col md={12}>
                            <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => navigate('/financeiro/generateborder')}> <text> <S.FAopen /> Criar Borderô </text></button>
                            <button type="button" className="btn btn-primary btn-sm btn-block" style={{ marginLeft: "20px" }} onClick={() => navigate('/financeiro/upcnab')}> <text> <S.FAdown /> Receber Arquivo</text></button>

                            <br></br><br></br>

                            <S.cardTable>
                                <S.textTitleTble>Títulos em aberto</S.textTitleTble>

                                <Table striped size="md" responsive>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Pagador</th>

                                            <th>Recebedor</th>
                                            <th>Valor</th>
                                            <th>Vencimento</th>
                                            <th>Cod.Banco</th>
                                            <th>Farol</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataApi.map((item) => (
                                            <tr key={item.hash}>
                                                <td>{item.codigo}</td>
                                                <td>{item.Pagador}</td>
                                                <td>{item.Recebedor}</td>
                                                <td>R$ {item.Valor}</td>
                                                <td>{item.Vencimento}</td>
                                                <td>{item.Cob}</td>
                                                {item.StatusCod === '1' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "yellow", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '2' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "green", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '3' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "orange", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '4' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "gray", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '5' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "gray", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '6' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "gray", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '7' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "gray", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '8' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "gray", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '9' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "red", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '10' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "red", margin: "10px", }}> </div></>)}
                                                {item.StatusCod === '11' && (<> <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "red", margin: "10px", }}> </div></>)}


                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                Legenda:
                                <br></br>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '10px', justifyContent: 'space-between', align: 'center' }}>

                                    <div> Pendente de aprovação: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "yellow", margin: "10px", }} /> </div>
                                    <div> Aprovado para pagamento: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "green", margin: "10px", }} /> </div>
                                    <div> Arquivo enviado para pagamento: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "orange", margin: "10px", }} /> </div>
                                    <div> Recebido / Realizado: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "gray", margin: "10px", }} /> </div>
                                    <div> Pagamento com erro: <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "red", margin: "10px", }} /> </div>

                                </div>
                            </S.cardTable>
                        </Col>
                    </Row>
                </S.section>
                <Footer />
            </S.body>

        </>


    );
}


