import React, { Component, useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import *  as S from './style';
import "./index.css";
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useNavigate } from "react-router-dom";




import { Container, Row, Col, Table, Modal, Button } from 'react-bootstrap';




export default function OpenLote() {

    const { id } = useParams();
    const [dataApi, setDataApi] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        async function chamaapi() {


            var data = JSON.stringify({
                "uuid": "958610d3-27e0-414d-feeb-a04b4144808e18202112182348",
                "hash_lote": id
            });

            var axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',

                }
            };

            // 'Authorization': `Bearer ${user.token}`,


            const resposta = await ilikeApi.post("/pag-remessa/open-lote", data, axiosConfig);
            if (resposta.data.error === 0) {

                setDataApi(resposta.data.res);

            }
            console.log(resposta.data)

        }

        chamaapi();

    }, []);





    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Aprovação de lote de pagamentos via remessa
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Confirmação</h4>
                    <p>
                        Declaro que estou de acordo em aprovar o lote para pagamento e tenho total responsabilidade por todos os pagamentos que serão liquidados nesta remessa.
                    </p>
                    <small> Id do lote: {id} </small>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => alert(`Função Indisponivel`)}>  <S.FaCheckLo /> Aceito</button>

                    <Button type="button" className="btn btn-danger btn-sm btn-block" onClick={props.onHide}>Não aceito</Button>
                </Modal.Footer>
            </Modal>
        );
    }


    return (
        <>
            <Menu />
            <S.body>
            <S.nameuser> <S.FaUse /> Bem vindo -  Emerson Rodrigues de Freitas</S.nameuser>

                <S.textTitleScreen>Títulos do lote</S.textTitleScreen>
                <Divider />
                <S.section>


                    <Row>

                        <S.cardTable>
                        <S.textTitleTble>Títulos</S.textTitleTble>


                            <Table striped size="sm" responsive>
                                <thead>
                                    <tr>
                                        <th>Título</th>
                                        <th>Recebedor</th>
                                        <th>Valor</th>
                                        <th>Dt. Vencimento</th>
                                        <th>Remover</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {dataApi.map((item) => (
                                        <tr key={item.SJ3_NUM}>
                                            <td>{item.SJ3_NUM}</td>
                                            <td>{item.SJ4_NM_RECEBEDOR}</td>
                                            <td> R$ {item.SJ3_VALOR_TITULO}</td>
                                            <td>{item.SJ3_DT_VENCIMENTO}</td>
                                            <td>  <button type="button" className="btn btn-link btn-sm btn-block" onClick={() => alert(`Função Indisponivel`)}>  <S.FaRemove /></button> </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => setModalShow(true)}>  <S.FaCheckLo /> Aprovar Lote</button>



                            <MyVerticallyCenteredModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />
                        </S.cardTable>


                    </Row>
                </S.section>
                <Footer />
            </S.body>


        </>


    );
}


