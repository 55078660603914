import React, { useState } from 'react';
import * as S from './style'
import { Container, Row, Col, Table, Alert } from 'react-bootstrap';


export default function Alerta({ title,  message, color, show }) {


    return (

        <>
            {show ? (
                <S.Container>
                    <Alert variant= { color }>
                        <Alert.Heading>{title}</Alert.Heading>
                        <p>
                           {message}
                        </p>
                        
                    </Alert>
                </S.Container>
            ): (
                <div />
            )}
        </>

    )
}
