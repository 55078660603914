import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import MainRoutes from './routes';
import AppProvider from './hooks';
import { AlertaProvider } from './hooks/alerta';
export default function App() {
  return (
    <Router>
      <AlertaProvider>
        <AppProvider>
          <MainRoutes />
        </AppProvider>
      </AlertaProvider>
    </Router>
  );
}