import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import * as S from '../../style.js'
import Logo from '../../../../img/logoblack.png'
import { Dropdown } from 'react-bootstrap';
import { useAuth } from "../../../../hooks/Authetication.js";
const Sidebar = ({ sidebar }) => {

  const navigate = useNavigate();
  const { signOut } = useAuth();
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  const departamento = 'Financeiro';

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
      
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
        </div>
      );
    },
  );







  return (
    <>

      <div className={sidebar ? "sidebar sidebar--open" : "sidebar"}>
        <S.headerLogo src={Logo} />

        <S.menu> 

        <S.box> 
        <li  onClick={()=> navigate(`/${departamento}/dashboard`)}>  <i className="ri-home-8-line"></i> Dashboard</li>
       
      { departamento === 'Financeiro' && (
        <> 
        <Dropdown> 
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <li> <i className="ri-search-eye-line"></i> Consultas <i className="ri-arrow-down-s-line"></i> </li>           
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item eventKey="1" onClick={()=> navigate('/Financeiro/extrato')}> <i className="ri-file-list-line"></i> Extrato</Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={()=> navigate('/Financeiro/listaLotes')}><i className="ri-archive-line"></i> Lotes </Dropdown.Item>
                <Dropdown.Item eventKey="3" onClick={()=> navigate('/Financeiro/listbordero')}><i className="ri-currency-fill"></i>Borderô</Dropdown.Item>
                {/* <Dropdown.Item eventKey="4" onClick={()=> navigate('/Financeiro/consulta')}><i className="ri-currency-fill"></i> Lancamentos</Dropdown.Item> */}
              </Dropdown.Menu>
        </Dropdown>
        
        <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <li> <i className="ri-money-dollar-box-line"></i> Financeiro <i className="ri-arrow-down-s-line"></i> </li>           
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item eventKey="1" onClick={()=> navigate('/financeiro/billstopay')}> <i className="ri-file-list-line"></i> Contas a Pagar</Dropdown.Item>
                <Dropdown.Item eventKey="1" onClick={()=> navigate('/financeiro/movimento')}> <i className="ri-exchange-line"></i> Movimento Interno</Dropdown.Item>

                {/* <Dropdown.Item eventKey="2">Transferência única</Dropdown.Item> */}
              </Dropdown.Menu>
        </Dropdown>
       
        </>
       
       
       )}
       
       
       
       
       
        {/* <li onClick={()=> navigate('/consulta')}> <i className="ri-search-eye-line"></i> Consultas</li> */}
        </S.box>
        <S.box> 
        <div>
          <li onClick={()=> signOut(true)} > <i className="ri-logout-box-line"></i> Sair</li>
        </div>
        </S.box>

        </S.menu>

      </div>
    </>
  );
};

export default Sidebar;