import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useAuth } from "../../../hooks/Authetication";
import { useAlerta } from "../../../hooks/alerta";
import { useNavigate } from "react-router-dom";

import { Container, Row, Col, Table } from 'react-bootstrap';

export default function UpCnab() {

    const [dataApi, setDataApi] = useState([]);
    const { user } = useAuth();
    const { showAlert, showAlertTime } = useAlerta();
    const [selectedFile, setSelectedFile] = useState("");

    const navigate = useNavigate();

    // useEffect(() => {

    //     async function chamaapi() {

    //         var data = JSON.stringify({
    //             "uuid": user.uuid,
    //             "filter": {
    //                 "periodo_inicio": null,
    //                 "periodo_fim": null,
    //                 "status": '1,2,3,4,5,6,7,8'
    //             }
    //         });

    //         var axiosConfig = {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             }
    //         };

    //         // 'Authorization': `Bearer ${user.token}`,


    //         const resposta = await ilikeApi.post("/consultas-fin-adm/list-titulos", data, axiosConfig);
    //         if (resposta.data.error === 0) {
    //             setDataApi(resposta.data.res);

    //         }
    //         console.log(resposta.data)

    //     }

    //     //   chamaapi();

    // }, []);


    async function sendUpload() {

        setDataApi([]);


        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
            }
        };


        var FormData = require('form-data');
        var data = new FormData();
        data.append('arquivo', selectedFile);
        const resposta = await ilikeApi.post("/cnab-fin-adm/ret-cnab", data, axiosConfig);
        
        if(resposta.data.erro === 0){
            setDataApi(resposta.data.res);
        }
        

       
       
    }


    return (
        <>
            <Menu />
            <S.body>
                <S.nameuser> <S.FaUse /> Bem vindo -  {user.name}</S.nameuser>
                <S.textTitleScreen>Conta a Pagar / Upload de Arquivo</S.textTitleScreen>
                <Divider />
                <S.section>



                    <br></br><br></br>

                    <Row>
                        <Col md={12}>
                            <input
                                type="file"
                                // value={selectedFile}
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                            />
                            <button type="button" className="btn btn-success btn-sm btn-block" onClick={() => sendUpload()}> <text> <S.FAdown /> Enviar </text></button>

                            <br></br><br></br>

                            <S.cardTable>
                                <S.textTitleTble>Títulos no arquivo</S.textTitleTble>

                                <Table striped size="md" responsive>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Pagador</th>
                                            <th>Recebedor</th>
                                            <th>Valor</th>
                                            <th>Msg</th>
                                            <th>Retorno Banco</th>
                                            <th>Farol</th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataApi.map((item) => (
                                            <tr key={item.hash}>
                                                <td>{item.codtitulo}</td>
                                                <td>{item.pagador}</td>
                                                <td>{item.favorecido}</td>
                                                <td>R$ {item.valor}</td>
                                                <td>{item.msg}</td>
                                                <td>{item.msgRet}</td>
                                                {item.cod === 6 ? 
                                                (
                                                <> 
                                                <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "green", margin: "10px", }}> 
                                                </div>
                                                </>
                                                ) : (
                                                <>
                                               <div style={{ width: "3px", height: "3px", borderRadius: "50%", backgroundColor: "red", margin: "10px", }}> 
                                               
                                               </div>

                                                </>
                                                )
                                                }
                                              


                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </S.cardTable>
                        </Col>
                    </Row>
                </S.section>
                <Footer />
            </S.body>

        </>


    );
}


