import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'




const Backdrop = ({sidebar, closeSidebar}) => {
  return (
    <>

      <div className={ sidebar?"backdrop backdrop--open": "backdrop" } onClick={ ()=> {closeSidebar()}}>
      


      </div>
    </>

  );
};

export default Backdrop;