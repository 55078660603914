



import { Button, Stack, Row, Col, Table, Pagination } from 'react-bootstrap';

import { PaginationItem } from "./PaginationItem";

const siblingsCount = 1;

function generatePagesArray(from, to) {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1;
    })
    .filter(page => page > 0);
}

export function MyPagination({
  totalCountOfRegisters,
  registerPerPage = 10,
  currentPage = 1,
  onPageChange
}) {
  const lastPage = Math.ceil(totalCountOfRegisters / registerPerPage);

  const previousPages = currentPage > 1
    ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
    : [];

  const nextPages = currentPage < lastPage
    ? generatePagesArray(currentPage, Math.min(currentPage + siblingsCount, lastPage))
    : [];

  return (
    <div
      
      style={{ display: 'flex', flexDirection: 'row', marginLeft:'20px', marginTop: '10px', justifyContent: 'space-between', align: 'center', }}
    >
      <div>
        {/* <strong>0</strong> - <strong>10</strong> de  */}
        <strong>Total de registros: {totalCountOfRegisters}</strong>
      </div>


      <div style={{ display: 'flex', flexDirection: 'row' ,marginLeft:'20px', }}>
        {currentPage > (1 + siblingsCount) && (
          <>
            <PaginationItem  onPageChange={onPageChange} number={1} />
            {currentPage > (2 + siblingsCount) && (
              <p  color="gray.400" width="8" textAlign="center">...</p>
            )}
          </>
        )}

        {previousPages.length > 0 && previousPages.map(page => {
          return <PaginationItem onPageChange={onPageChange} key={page} number={page} />
        })}

        <PaginationItem onPageChange={onPageChange} number={currentPage} isCurrent />

        {nextPages.length > 0 && nextPages.map(page => {
          return <PaginationItem onPageChange={onPageChange} key={page} number={page} />
        })}

        {(currentPage + siblingsCount) < lastPage && (
          <>
            {(currentPage + 1 + siblingsCount) < lastPage && (
              <p color="gray.400" width="8" textAlign="center">...</p>
            )}
            <PaginationItem onPageChange={onPageChange} number={lastPage} />
          </>
        )}

      </div>
    </div>
  );
}