import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import *  as S from './style';
import Imglogo from '../../../img/logo.png'
import bg from '../../../img/bg01.jpg'
import Menu from "../../../components/menu";
import Main from "../../../components/main";
import Footer from "../../../components/footer";
import Divider from "../../../components/divider";
import ilikeApi from "../../../utils/api/ilikepay";
import { useAuth } from "../../../hooks/Authetication";
import { useAlerta } from "../../../hooks/alerta";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";


import { Container, Row, Col, Table } from 'react-bootstrap';
import axios from "axios";

export default function Dashboard() {

    const [dataApi, setDataApi] = useState([]);
    const [dataSaldo, setDataSaldo] = useState([]);

    const [dataGrafico, setDataGrafico] = useState([]);

    
    
    
    ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

    const { user } = useAuth();
    const { showAlert, showAlertTime } = useAlerta();

    const chartData = [
        {
          label: "Venezuela",
          value: "290"
        },
        {
          label: "Saudi",
          value: "260"
        },
        {
          label: "Canada",
          value: "180"
        },
        {
          label: "Iran",
          value: "140"
        },
        {
          label: "Russia",
          value: "115"
        },
        {
          label: "UAE",
          value: "100"
        },
        {
          label: "US",
          value: "30"
        },
        {
          label: "China",
          value: "30"
        }
      ];

      const chartConfigs = {
        type: "column2d", // The chart type
        width: "100%", // Width of the chart
        height: "400", // Height of the chart
        dataFormat: "json", // Data type
        dataSource: {
          // Chart Configuration
          chart: {
            caption: "Saidas por banco",    //Set the chart caption
            subCaption: "Gráfico de saída por banco",             //Set the chart subcaption
            numberSuffix: "",
            theme: "fusion"                 //Set the theme for your chart
          },
          // Chart Data - from step 2
          data: dataGrafico
        }
      };

    async function chamaSaldo() {

        var axiosConfig = {
            headers: {
                'Authorization': `Bearer ${user.access_token}`,
            }
        };
        try {
            const resposta = await ilikeApi.post("/banking-adm/consulta-saldo-dash/b3552ec6-6b49-42a3-8269-1dccd2f6fdba16202112092341", {}, {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`,
                }
            });
            setDataSaldo(resposta.data.res)

        } catch (error) {
            console.log(error)
        }
    }


    async function chamaGrafico() {

        const data = JSON.stringify({
            "metodo": "GQPB"
        });

        try {
            const resposta = await ilikeApi.post(`financeiro-consulta/consultas`, data, {
                headers: {
                    'Authorization': `Bearer ${user.access_token}`,
                }
            });

            setDataGrafico(resposta.data.res)
            console.log(dataGrafico)

        } catch (error) {
            console.log(error)
        }
    }

    async function chamaapi() {

        var data = JSON.stringify({
            "uuid": user.uuid,
            "periodo_inicio": null,
            "periodo_fim": null,
            "status": null
        });


        var axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.access_token}`,
            }
        };


        const resposta = await ilikeApi.post("/pag-remessa/lista-lotes", data, axiosConfig);
        if (resposta.data.error === 0) {
            setDataApi(resposta.data.res);

        }

    }

    useEffect(() => {
        chamaapi();
        chamaSaldo();
        chamaGrafico()
    }, []);


    return (
        <>
            <Menu />
            <S.body>
                <S.nameuser> <S.FaUse /> Bem vindo -  {user.name}</S.nameuser>
                <S.textTitleScreen onClick={() => chamaSaldo()}>Dashboard</S.textTitleScreen>
                <Divider />
                <S.section>


                    <Row>
                        <Col md={4}>
                            <S.card>

                                <S.textCardTitle>Caixa Empresa:</S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: {dataSaldo.saldoIlike}</S.textCard>
                            </S.card>
                        </Col>

                        <Col md={4}>
                            <S.card>

                                <S.textCardTitle>Caixa Geral:</S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: {dataSaldo.saldoTotal}</S.textCard>
                            </S.card>
                        </Col>

                        <Col md={4}>
                            <S.card>

                                <S.textCardTitle>Caixa Clientes:</S.textCardTitle>
                                <br></br>
                                <S.textCard>R$: {dataSaldo.saldoClientes}</S.textCard>
                            </S.card>
                        </Col>





                    </Row>

                    <br></br><br></br>

                    <Row>

                    { (<ReactFC {...chartConfigs} />)}

                    </Row>
                                        

                    
                </S.section>
                <Footer />
            </S.body>

        </>


    );
}


