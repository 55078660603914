import { Button, Row, Col, Table, Pagination } from 'react-bootstrap';


export function PaginationItem({ isCurrent = false, number, onPageChange }) {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        width="4"
        colorScheme="orange"
        disabled
        _disabled={{
          bgColor: 'orange.500',
          cursor: 'default'
        }}
        style={{margin:'3px'}}
      >
        {number}
      </Button>
    );
  }
  return (
    <Button
      size="sm"
      fontSize="xs"
      width="4"
      bgColor='gray.200'
      _hover={{
        bgColor: 'gray.300'
      }}
      onClick={() => onPageChange(number)}
      style={{margin:'3px'}}

    >
      {number}
    </Button>
  );
}